//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _filter from 'lodash.filter'
import _assign from 'lodash.assign'
import _map from 'lodash.map'

export default {
    name: "LayoutMenu",

    data() {
        return {
            selectedApp: null
        }
    },

    props: {
        config: {
            type: Object,
            default: null
        }
    },

    computed: {
        apps() {
            return this.$theme.$store.getters['getVisibleMenuApps']
        },

        minimised() {
            return this.$theme.$store.getters['isMainMenuMinimised']
        },

        urls() {
            if (this.config !== null) {
                return this.config
            }

            return this.$theme.$store.getters['getMainMenuUrls']
        },

        loading() {
            return this.$theme.$store.getters['getLoadingResources']
        },

        menuItemText() {
            return (defaultText, icon) => this.minimised ? '<i class="' + icon + ' fa-2x"></i>' : defaultText
        }
    },

    methods: {
        minimiseSidebar() {
            if(this.minimised === true) {
                return this.closeSidebar()
            }

            this.$theme.$store.commit('minimiseMainMenu')
        },

        closeSidebar() {
            this.$theme.$store.commit('closeMainMenu')
        },

        openSidebar() {
            this.$theme.$store.commit('maximiseMainMenu')
        },

        getAppLinks(app) {
            return this.$theme.$store.getters['getMenuAppLinks'](app)
        },

        loadLinks(app) {
            this.selectedApp = app

            if(this.minimised == true) {
                this.openSidebar()
            }

            const clientId = app.extra_data.client_id
            app.loadingLinks = true

            this.$app.get('logger').info(`Started loading menu links for ${clientId}`)

            this.$app.get('http.request').get(this.urls.links, {
                params: { app_id: clientId }
            })
                .then(resp => {
                if (resp.status !== 200) {
                    this.$app.get('logger').error('Failed to load app links', resp)
                } else {
                    this.$theme.$store.commit('setResourceLinks', resp.data)
                }
            })
            .finally(() => app.loadingLinks = false)
        },

        isSelectedApp(app) {
            return this.selectedApp !== null && this.selectedApp.id === app.id
        }
    },

    created() {
        this.$theme.$store.commit('startLoadingResourceApps')

        this.$app.get('http.request').get(this.urls.apps).then(resp => {
            if (resp.status !== 200) {
                this.$app.get('logger').error('Failed to load menu apps', resp)
            } else {
                this.$theme.$store.commit('stopLoadingResourceApps')

                this.$theme.$store.commit('setResourceApps', resp.data)
            }
        })
    }
}
