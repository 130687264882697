import stylesheets from '../scss/app.scss'

import LayoutHeader from './layout/LayoutHeader'
import LayoutMenu from './layout/LayoutMenu'
import LayoutPageDefault from './layout/LayoutPageDefault'
import LayoutPageMenuHidden from './layout/LayoutPageMenuHidden'

import LoginPage from './pages/auth/Login'

export default {
	LayoutHeader,
	LayoutMenu,
	LayoutPageDefault,
	LayoutPageMenuHidden,
	LoginPage
}
