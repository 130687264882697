//
//
//
//
//
//
//
//

export default {
    name: "layout-page-menu-hidden"
}
