//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cookie from 'cookie'
import moment from 'moment'
import { notifications } from '../../../config'

export default {
	name: 'Login',

	data() {
		return {
			email: null,
			domain: null,
            user: this.$app.user,
            rememberMe: false,
            storedUserEmail: cookie.parse(document.cookie).user_email,
            notification: null
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (vm.$app.user.isAuthenticated()) {
				next({ name: 'home' })
			} else {
				next()
			}
		})
	},

	methods: {
		login() {
            console.log(this.$app.user)
			const identity = this.$app.get('identity')
			let identityProvider = identity.identify(this.email)

			if (identityProvider === null) {
				identityProvider = identity.getDefaultIdp()
			}

			const loginUrl = this.user.loginUrl({
				loginHint: this.email,
				idpHint: identityProvider.getHint()
            })

            if(this.rememberMe) {
                document.cookie = cookie.serialize('user_email', this.email, {
                    expires: moment().add(10, 'd').toDate()
                })
            }

			this.redirectTo(loginUrl)
		},

		redirectTo(url) {
			window.location.href = url
        }
    },

    watch: {
        rememberMe(newVal, oldVal) {
            if(newVal === false && this.storedUserEmail !== undefined) {
                document.cookie = cookie.serialize('user_email', '', { expires: moment().toDate() })
            }
        }
    },

    mounted() {
        if(this.storedUserEmail !== undefined) {
            this.email = this.storedUserEmail
            this.rememberMe = true
        }
    },

    created() {
        if(Object.keys(this.$route.query).indexOf('pwsuccess') !== -1) {
            this.notification = notifications.success.pwsuccess
        }
    }
}
