import Vuex from 'vuex'
import components from './components'
import themeStore from './store'

const install = (Vue, config) => {
	if (install.installed) return;
	install.installed = true;

	Vue.component('LayoutHeader', components.LayoutHeader)
	Vue.component('LayoutMenu', components.LayoutMenu)
	Vue.component('LayoutPageDefault', components.LayoutPageDefault)
	Vue.component('LayoutPageMenuHidden', components.LayoutPageMenuHidden)
	Vue.component('LoginPage', components.LoginPage)

    Vue.use(Vuex)

    const theme = {}

    theme.$store = new Vuex.Store(themeStore)

    theme.$store.dispatch('setConfig', config)

    Vue.prototype.$theme = theme
}

const plugin = {
	install
};

let GlobalVue = null;
if (typeof window !== 'undefined') {
	GlobalVue = window.Vue;
} else if (typeof global !== 'undefined') {
	GlobalVue = global.Vue;
}
if (GlobalVue) {
	GlobalVue.use(plugin);
}

export {
	components,
	install
}
