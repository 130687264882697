import _assign from 'lodash.assign'
import _filter from 'lodash.filter'

export default {
    state: {
        layout: {
            menus: {
                main: {
                    minimised: false,
                    closed: false
                }
            }
        },
        menus: {
            app: {
                clientId: null
            },
            main: {
                urls: {
                    apps: null,
                    links: null
                }
            }
        },
        resources: {
            apps: [],
            links:[]
        },
        loading: {
            resources: {
                apps: false
            }
        }
    },

    getters: {
        getMainMenuUrls(state) {
            return state.menus.main.urls
        },

        isMainMenuMinimised(state) {
            return state.layout.menus.main.minimised
        },

        isMainMenuClosed(state) {
            return state.layout.menus.main.closed
        },

        getVisibleMenuApps(state) {
            return _filter(state.resources.apps, app => app.visible)
        },

        getMenuAppClientId(state) {
            return state.menus.app.clientId
        },

        getMenuAppLinks(state) {
            return app => _filter(state.resources.links, link => link.extra_data.app_id === app.id)
        },

        getLoadingResources(state) {
            return state.loading.resources
        }
    },

    mutations: {
        setResourceApps(state, apps) {
            state.resources.apps = apps
        },

        setResourceLinks(state, links) {
            state.resources.links = links
        },

        setAppMenuClientId(state, clientId) {
            state.menus.app.clientId = clientId
        },

        setMainMenuAppsUrl(state, appsUrl) {
            state.menus.main.urls.apps = appsUrl
        },

        setMainMenuLinksUrl(state, linksUrl) {
            state.menus.main.urls.links = linksUrl
        },

        minimiseMainMenu(state) {
            state.layout.menus.main.minimised = true
        },

        maximiseMainMenu(state) {
            state.layout.menus.main.closed = false
            state.layout.menus.main.minimised = false
        },

        closeMainMenu(state) {
            state.layout.menus.main.closed = true
        },

        startLoadingResourceApps(state) {
            state.loading.resources.apps = true
        },

        stopLoadingResourceApps(state) {
            state.loading.resources.apps = false
        }
    },

    actions: {
        setConfig({commit}, config) {
            config = _assign({
                appMenu: null,
                mainMenu: {
                    apps: null,
                    links: null
                }
            }, config)

            if (config.mainMenu.apps === null) {
                console.warn('The main menu apps url hasn\'t been configured')
            }

            if (config.mainMenu.links === null) {
                console.warn('The main menu links url hasn\'t been configured')
            }

            commit('setAppMenuClientId', config.appMenu)
            commit('setMainMenuAppsUrl', config.mainMenu.apps)
            commit('setMainMenuLinksUrl', config.mainMenu.links)
        }
    }
}
