//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "layout-page-default",

    computed: {
        layout() {
            return {
                closedSidebar: this.$theme.$store.getters['isMainMenuClosed'],
                minimisedSidebar: this.$theme.$store.getters['isMainMenuMinimised']
            }
        },

        menuColumnClasses() {
            return {
                'col-2': !this.layout.minimisedSidebar,
                'col-1': this.layout.minimisedSidebar && !this.layout.closedSidebar,
                'p-fixed': true
            }
        },

        mainColumnClasses() {
            return {
                'col-10': !this.layout.minimisedSidebar,
                'offset-2': !this.layout.minimisedSidebar,
                'col-11': this.layout.minimisedSidebar && !this.layout.closedSidebar,
                'offset-1': this.layout.minimisedSidebar && !this.layout.closedSidebar,
                'col-12': this.layout.closedSidebar,
                'offset-0': this.layout.closedSidebar
            }
        }
    }
}
