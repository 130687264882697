//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "LayoutHeader",

    props: {
        clientId: {
            type: String,
            default: null
        },
        config: {
            type: Object,
            default() {
                return {
                    apps: null,
                    links: null
                }
            }
        }
    },

    data() {
        return {
            user: this.$app.user,
            showMultiMenu: false,
            loadingLinks: false,
            links: [],
            menuContainerHeight: 0,
            maxContainerHeight: 245,
            canScroll: true,
            customerName: null
        }
    },

    computed: {
        layout() {
            return {
                closedSidebar: this.$theme.$store.getters['isMainMenuClosed'],
            }
        },

        appMenuClientId() {
            this.$theme.$store.getters['getMenuAppClientId']
        },

        urls() {
            if (this.config !== null) {
                return config
            }

            return this.$theme.$store.getters['getMainMenuUrls']
        },

        logoutUrl() {
            const user = this.user

            return user.logoutUrl()
        },

        scrollable() {
            return this.canScroll
        }
    },

    methods: {
        openSidebar() {
            this.$theme.$store.commit('maximiseMainMenu')
        },

        toggleMultiMenu() {
            this.loadLinks()
            this.showMultiMenu = ! this.showMultiMenu
        },

        loadLinks(app) {
            const clientId = this.clientId
            this.loadingLinks = true

            this.$app.get('logger').info(`Started loading menu links for ${clientId}`)

            this.$app.get('http.request').get(this.config.links, {
                    params: { app_id: clientId }
                })
                .then(resp => {
                    if (resp.status !== 200) {
                        this.$app.get('logger').error('Failed to load app links', resp)
                    } else {
                        this.links = resp.data

                        this.$app.get('logger').debug('App links loaded', this.links)
                    }
                })
                .finally(() => this.loadingLinks = false)
        },

        closeMenu(e) {
            if (!this.$el.contains(e.target)){
                this.showMultiMenu = false
            }
        },

        getScrollContainerElement() {
            return document.getElementById('menu-content')
        },

        getLinkContainerElement() {
            return document.getElementById('menu-links')
        },

        menuHeight() {
            let menuContent = this.getLinkContainerElement()

            if(menuContent.offsetHeight > 0) {
                this.menuContainerHeight = menuContent.offsetHeight
            }
        },

        scrollLinks() {
            let menuContent = this.getScrollContainerElement()

            menuContent.scrollTop += 240;

            this.checkScroll()
        },

        checkScroll() {
            let menuContent = this.getScrollContainerElement()

            if(menuContent.scrollTop + this.maxContainerHeight === this.menuContainerHeight) {
                this.canScroll = false
                return
            }

            this.canScroll = true
        },

    },

    created() {
        if(this.$app.user) {
            const accountId = JSON.parse(atob(this.$app.user.token.split('.')[1])).n4_account_number
            
            this.$app.get('http.request')
                .get(`https://www.n4portal.com/api-backend/accountdata/api/SimpleCRMAccounts/GetAccountbyAccountID?accountID=${accountId}`)
                .then((response) => {
                    if(response.data.content != null) {
                        this.customerName = response.data.content.name
                    }
                })
                .catch(error => console.log(error))
        }
    },

    watch: {
        loadingLinks() {
            this.menuHeight()
        },

        showMultiMenu() {
            this.menuHeight()
        }
    }
}
